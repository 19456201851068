<template>
  <div>
    <p class="title">Dados do solicitante</p>
    <form class="relative" @submit.prevent="submit">
      <div class="backdrop" v-show="loadingPage">
        <div class="fas fa-dollar-sign spin"></div>
      </div>
      <p class="form_title">DADOS PESSOAIS</p>
      <div class="row">
        <v-input class="w-full" name="nomeCompleto" v-capitalizeName v-model="dadosPessoais.nomeCompleto"
          :error="errors.first('nomeCompleto')" label="Nome Completo*" v-validate="'required|fullName'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/4 lg:pr-6" name="cpf" v-model="dadosPessoais.cpf" :error="errors.first('cpf')"
          label="CPF*" mask="###.###.###-##" v-validate="'required|cpf'"></v-input>
        <v-input class="w-full lg:w-1/4 lg:pr-6" name="dataNascimento" v-model="dadosPessoais.dataNascimento"
          :error="errors.first('dataNascimento')" mask="##/##/####" :masked="true" label="Data de Nascimento*"
          v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
        <v-input class="w-full lg:w-1/4 lg:pr-6" name="sexo" v-model="dadosPessoais.sexo" :error="errors.first('sexo')"
          label="Sexo*" :options="sexos" v-validate="{ required: true, is_not: -1 }" itemText="descricao"
          itemValue="id"></v-input>
        <v-input class="w-full lg:w-1/4" name="estadoCivil" v-model="dadosPessoais.estadoCivil"
          :error="errors.first('estadoCivil')" label="Estado Civil*" :options="estadosCivis"
          v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="id"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/4 lg:pr-6" name="rg" v-model="dadosPessoais.rg" :error="errors.first('rg')"
          label="RG*" v-validate="'required'"></v-input>
        <v-input class="w-full lg:w-1/4 lg:pr-6" name="dataExpedicaoRg" v-model="dadosPessoais.dataExpedicaoRg"
          :error="errors.first('dataExpedicaoRg')" mask="##/##/####" :masked="true" label="Data de Expedição RG*"
          v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
        <v-input class="w-full lg:w-1/4 lg:pr-6" name="orgaoExpedicaoRG" v-model="dadosPessoais.orgaoExpedicaoRG"
          :error="errors.first('orgaoExpedicaoRG')" label="Órgão de Expedição RG*"
          v-validate="'required|min:2'"></v-input>
        <v-input class="w-full lg:w-1/4" name="ufExpedicaoRg" v-model="dadosPessoais.ufExpedicaoRg"
          :error="errors.first('ufExpedicaoRg')" label="UF RG*" mask="AA" v-validate="'required|length:2'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/3 lg:pr-6" name="nacionalidade" v-model="dadosPessoais.nacionalidade"
          :error="errors.first('nacionalidade')" label="Nacionalidade*" :options="computedNacionalidades"
          v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="descricao"></v-input>
        <v-input v-if="isBR" class="w-full lg:w-1/3 lg:pr-6" name="ufNascimento" v-model="dadosPessoais.ufNascimento"
          :error="errors.first('ufNascimento')" label="UF de Nascimento*" :options="computedUfs"
          v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="descricao"></v-input>
        <v-input v-else class="w-full lg:w-1/3 lg:pr-6" name="ufNascimento" v-model="dadosPessoais.ufNascimento"
          :error="errors.first('ufNascimento')" label="UF de Nascimento*" v-validate="'required'"></v-input>
        <v-input v-if="isBR" class="w-full lg:w-1/3 lg:pr-6" name="cidadeNascimento"
          v-model="dadosPessoais.cidadeNascimento" :error="errors.first('cidadeNascimento')" label="Cidade de Nascimento*"
          :options="computedCities" v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="descricao"
          autocomplete="off"></v-input>
        <v-input v-else class="w-full lg:w-1/3 lg:pr-6" name="cidadeNascimento" v-model="dadosPessoais.cidadeNascimento"
          :error="errors.first('cidadeNascimento')" label="Cidade de Nascimento*" v-validate="'required|min:2'"></v-input>
      </div>

      <div class="row">
        <v-input class="w-full lg:w-1/1" v-lowercase name="email" v-model="dadosPessoais.email"
          :error="errors.first('email')" label="E-mail pessoal*" v-validate="'required|email'"></v-input>
      </div>

    
      <div class="row">
        <v-input class="w-full lg:w-3/12 lg:pr-6" mask="(##) #####-####" :masked="true" name="celular"
          v-model="dadosPessoais.celular" :error="errors.first('celular')" label="Celular*"
          v-validate="'required|min:14|max:15'"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" mask="(##) ####-####" :masked="true" name="telefoneResidencial"
          v-model="dadosPessoais.telefoneResidencial" :error="errors.first('telefoneResidencial')"
          label="Telefone Residencial" v-validate="'min:14|max:15'"></v-input>
        <v-input class="w-full lg:w-3/12 lg:pr-6" name="tipoResidencia" v-model="dadosPessoais.tipoResidencia"
          :error="errors.first('tipoResidencia')" label="Tipo Residência*" :options="tiposResidencia"
          v-validate="{ required: true, is_not: -1 }" itemText="descricao" itemValue="id"></v-input>
        <div class="w-full lg:w-3/12 flex">
          <v-input class="w-full lg:w-7/12" name="tempoResidencia" v-model="dadosPessoais.tempoResidencia"
            :error="errors.first('tempoResidencia')" label="Tempo de residência*" mask="##"
            v-validate="'required|regex:^[0-9][0-9]?$'"></v-input>
          <v-input class="w-full lg:w-5/12" name="tempoResidenciaAnosMeses"
            v-model="dadosPessoais.tempoResidenciaAnosMeses" :error="errors.first('tempoResidenciaAnosMeses')" label=""
            :options="temposResidencia" v-validate="'required'" itemText="descricao" itemValue="id"></v-input>
        </div>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/2 lg:pr-6" name="nomeMae" v-capitalizeName v-model="dadosPessoais.nomeMae"
          :error="errors.first('nomeMae')" label="Nome Mãe*" v-validate="'required|fullName'"></v-input>
        <v-input class="w-full lg:w-1/2" name="nomePai" v-capitalizeName v-model="dadosPessoais.nomePai"
          :error="errors.first('nomePai')" label="Nome Pai"></v-input>
      </div>
        <div class="row">
          <v-input class="w-full lg:w-1/1 lg:pr-6" name="nomeConjugeCompanheiro"
            v-model="dadosPessoais.nomeConjugeCompanheiro" :error="errors.first('nomeConjugeCompanheiro')"
            label="Nome Cônjuge" v-validate="validateConjuge"></v-input>
        </div>

      <div class="row">
        <v-input class="w-full lg:w-1/2 lg:pr-6" name="cargo" v-model="dadosPessoais.cargo" :error="errors.first('cargo')"
          label="Cargo*" v-validate="'required|min:2'"></v-input>
        <v-input class="w-full lg:w-1/2" name="salario" v-model="dadosPessoais.salario" :error="errors.first('salario')"
          label="Salário" type="money" v-validate="'decimal:2|min_value:1|max_value:2147483647'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-1/2 lg:pr-6" name="porcentagemParticipacao"
          v-model.number="dadosPessoais.porcentagemParticipacao" :error="errors.first('porcentagemParticipacao')"
          label="Porcentagem de Participação" :mask="['# %', '## %', '#,## %', '##,## %', '###,## %']"
          v-validate="'required|decimal:2|min_value:0|max_value:10000'" @blur="checkPercentage"></v-input>
        <div class="row">
          <div class="boxes_group">
            <p class="subtitle">Que tipo de sócio você é?</p>
            <v-radio class="inline text-sm" name="socioAdministrador" v-model="dadosPessoais.socioAdministrador"
              label="Proprietário e/ou Administrador" :value="true" v-validate="'required'"></v-radio>
            <v-radio class="inline text-sm" name="socioAdministrador" v-model="dadosPessoais.socioAdministrador"
              label="Quotista" :value="false"></v-radio>
            <p class="text-xs text-red-600">{{ errors.first("socioAdministrador") }}</p>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="boxes_group w-full lg:w-1/2">
          <p class="subtitle">Você é proprietário/sócio ou representante legal da empresa?</p>
          <v-radio class="inline text-sm" name="responsavelLegalProcurador"
            v-model="dadosPessoais.responsavelLegalProcurador" label="Proprietário ou Sócio" :value="false"
            v-validate="'required'"></v-radio>
          <v-radio class="inline text-sm" name="responsavelLegalProcurador"
            v-model="dadosPessoais.responsavelLegalProcurador" label="Representante Legal" :value="true"></v-radio>
          <p class="text-xs text-red-600">{{ errors.first("responsavelLegalProcurador") }}</p>
        </div>

        <v-input class="w-full lg:w-1/2" name="patrimonioAproximado" v-model="dadosPessoais.patrimonioAproximado"
          :error="errors.first('patrimonioAproximado')" label="Patrimonio Aproximado" type="money"
          v-validate="'decimal:2|min_value:1|max_value:2147483647'"></v-input>

      </div>

      <div class="row">
        <v-input class="w-full lg:w-1/3 lg:pr-6" name="dataEntradaSociedade" v-model="dadosPessoais.dataEntradaSociedade"
          :error="errors.first('dataEntradaSociedade')" mask="##/##/####" :masked="true"
          label="Data de Entrada na Sociedade*" v-validate="'required|date_format:dd/MM/yyyy'"></v-input>
      </div>
      <p class="form_title">REDES SOCIAIS</p>
      <div class="row">
        <v-input class="w-full lg:w-1/3 lg:pr-6" name="facebook" v-model="dadosPessoais.facebook"
          :error="errors.first('facebook')" label="Facebook" prefix="facebook.com/" v-validate="'min:2'"></v-input>
        <v-input class="w-full lg:w-1/3 lg:pr-6" name="instagram" v-model="dadosPessoais.instagram"
          :error="errors.first('instagram')" label="Instagram" prefix="instagram.com/" v-validate="'min:2'"></v-input>
        <v-input class="w-full lg:w-1/3 " name="linkedin" v-model="dadosPessoais.linkedin"
          :error="errors.first('linkedin')" label="Linkedin" prefix="linkedin.com/" v-validate="'min:2'"></v-input>
      </div>
      <p class="form_title">ENDEREÇO</p>
      <div class="row">
        <v-input @blur="getCep" class="w-full lg:w-2/12 lg:pr-6" mask="#####-###" :masked="true" name="endereco_CEP"
          v-model="dadosPessoais.endereco_CEP" :error="errors.first('endereco_CEP')" label="CEP*"
          v-validate="'required|length:9'"></v-input>
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="endereco_Logradouro" v-model="dadosPessoais.endereco_Logradouro"
          :error="errors.first('endereco_Logradouro')" label="Logradouro*" v-validate="'required|min:5'"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" name="endereco_Numero" v-model="dadosPessoais.endereco_Numero"
          :error="errors.first('endereco_Numero')" label="Número*" v-validate="'required|min:1'"></v-input>
        <v-input class="w-full lg:w-2/12" name="endereco_Bairro" v-model="dadosPessoais.endereco_Bairro"
          :error="errors.first('endereco_Bairro')" label="Bairro*" v-validate="'required|min:2'"></v-input>
      </div>
      <div class="row">
        <v-input class="w-full lg:w-6/12 lg:pr-6" name="endereco_Complemento" v-model="dadosPessoais.endereco_Complemento"
          :error="errors.first('endereco_Complemento')" label="Complemento" v-validate="'min:2'"></v-input>
        <v-input class="w-full lg:w-2/12 lg:pr-6" @input="getCities" :options="ufs" itemText="name" itemValue="name"
          name="endereco_UF" v-model="dadosPessoais.endereco_UF" :error="errors.first('endereco_UF')" label="UF"
          v-validate="{ required: true, is_not: -1 }"></v-input>
        <v-input class="w-full lg:w-4/12" :options="cities" itemText="name" itemValue="name" name="endereco_Cidade"
          v-model="dadosPessoais.endereco_Cidade" :error="errors.first('endereco_Cidade')" label="Cidade"
          v-validate="{ required: true, is_not: -1 }" :disabled="!cities.length"></v-input>
      </div>
      <p class="form_title">REFERÊNCIA PESSOAL</p>
      <div class="row">
        <v-input class="w-full lg:w-1/2 lg:pr-6" name="referenciaPessoal1_Nome"
          v-model="dadosPessoais.referenciaPessoal1_Nome" :error="errors.first('referenciaPessoal1_Nome')"
          label="Nome Completo*" v-validate="'required|fullName'"></v-input>
        <v-input class="w-full lg:w-1/2" :mask="['(##) ####-####', '(##) #####-####']" :masked="true"
          name="referenciaPessoal1_Telefone" v-model="dadosPessoais.referenciaPessoal1_Telefone"
          :error="errors.first('referenciaPessoal1_Telefone')" label="Telefone*"
          v-validate="'required|min:14|max:15'"></v-input>
      </div>
      <p class="form_title">PESSOA EXPOSTA POLITICAMENTE</p>
      <div>
        <p class="text">Conforme definido pela Circular nº 3.461 de 24/07/2009 do Banco Central do Brasil, as instituições
          financeiras devem obter de seus clientes permanentes informações que permitam caracterizá-los ou não como
          pessoas expostas politicamente (PEP). Consideram-se PEP os agentes públicos que desempenham ou tenham
          desempenhado, nos últimos cinco anos, no Brasil ou em países, territórios e dependências estrangeiros, cargos,
          empregos ou funções públicas relevantes, assim como seus representantes, familiares e outras pessoas de seu
          relacionamento próximo.</p>
        <v-radio class="inline text-sm" name="pessoaPoliticamenteExposta"
          v-model="dadosPessoais.pessoaPoliticamenteExposta" label="Sim" :value="true" v-validate="'required'"></v-radio>
        <v-radio class="inline text-sm" name="pessoaPoliticamenteExposta"
          v-model="dadosPessoais.pessoaPoliticamenteExposta" label="Não" :value="false"></v-radio>
        <p class="text-xs text-red-600">{{ errors.first("pessoaPoliticamenteExposta") }}</p>
      </div>
      <p class="form_title">TERMOS E CONDIÇÕES</p>
      <div class="relative">
        <p class="text">Declaro que as informações acima são a expressão da verdade e autorizo a Instituição a consultar
          órgãos especificos de proteção ao crédito e central de risco de crédito do Banco Central do Brasil, permitindo a
          análise dos créditos a conceder, o arquivamento de meus dados cadastrais, idoneidade e histórico, assim como o
          fornecimento de informações e de performance de pagamento de minhas operações para a Central de Risco de
          Crédito, administrada pelo Banco Central do Brasil, Associações Comerciais, SERASA e demais órgãos similares,
          públicos ou privados , os quais poderão deles se utilizar, respeitadas as disposições legais vigentes. Declaro
          ainda estar ciente de que deverei informar qualquer alteração à instituição nas informações acima prestadas.</p>
        <v-radio class="inline text-sm" name="termos" v-model="dadosPessoais.termos" label="Sim" :value="true"
          v-validate="'required:true'"></v-radio>
        <v-radio class="inline text-sm" name="termos" v-model="dadosPessoais.termos" label="Não" :value="false"></v-radio>
        <p class="text-xs text-red-600">{{ errors.first("termos") }}</p>
      </div>
      <div class="row mt-6" style="display: none">
        <div class="w-full lg:w-1/2 lg:pr-6">
          <v-checkbox class="my-1 text-sm" name="email" v-model="autorizacoesContato.email"
            label="Aceito receber informações e contatos por E-mail" />
        </div>
        <div class="w-full lg:w-1/2">
          <v-checkbox class="my-1 text-sm" name="sms" v-model="autorizacoesContato.sms"
            label="Aceito receber informações e contatos por SMS" />
        </div>
      </div>
      <div class="row" style="display: none">
        <div class="w-full lg:w-1/2 lg:pr-6">
          <v-checkbox class="my-1 text-sm" name="telefone" v-model="autorizacoesContato.telefone"
            label="Aceito receber informações e contatos por Telefone" />
        </div>
        <div class="w-full lg:w-1/2">
          <v-checkbox class="my-1 text-sm" name="whatsApp" v-model="autorizacoesContato.whatsApp"
            label="Aceito receber informações e contatos por WhatsApp" />
        </div>
      </div>
      <div class="row items-center lg:items-start justify-center">
        <v-button color="primary" class="my-4" size="lg" :loading="loading">Salvar e Avançar</v-button>
      </div>
    </form>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VCheckbox from '@/components/Inputs/Checkbox'
import VRadio from '@/components/Inputs/Radio'
import VButton from '@/components/Buttons/Button'
import CapitalizeName from '@/mixins/capitalizeName'

import Cep from '@/services/Commons/Cep'
import Forms from '@/services/Business/Forms'

export default {
  name: 'personal-data',
  components: { VInput, VButton, VCheckbox, VRadio },
  mixins: [CapitalizeName],
  data() {
    return {
      termos: false,
      dadosPessoais: {
        nomeCompleto: '',
        cpf: '',
        dataNascimento: '',
        nacionalidade: -1,
        ufNascimento: '',
        cidadeNascimento: '',
        rg: '',
        dataExpedicaoRg: '',
        orgaoExpedicaoRG: '',
        ufExpedicaoRg: '',
        sexo: -1,
        estadoCivil: -1,
        nomeConjugeCompanheiro: '',
        email: '',
        facebook: '',
        instagram: '',
        linkedin: '',

        endereco_CEP: '',
        endereco_Logradouro: '',
        endereco_Numero: '',
        endereco_Bairro: '',
        endereco_Complemento: '',
        endereco_Cidade: -1,
        endereco_UF: -1,

        celular: '',
        telefoneResidencial: null,
        tipoResidencia: -1,
        tempoResidencia: '',
        tempoResidenciaAnosMeses: 1,
        nomePai: '',
        nomeMae: '',
        cargo: '',
        salario: 0,
        patrimonioAproximado: 0,
        dataEntradaSociedade: '',
        socioAdministrador: '',
        porcentagemParticipacao: '',
        responsavelLegalProcurador: '',

        referenciaPessoal1_Nome: '',
        referenciaPessoal1_Telefone: '',

        pessoaPoliticamenteExposta: ''
      },

      autorizacoesContato: {
        email: true,
        sms: true,
        telefone: true,
        whatsApp: true
      },

      loading: false,
      loadingPage: true,

      sexos: [],
      ufs: [],
      estadosCivis: [],
      tiposResidencia: [],
      temposResidencia: [],
      cities: [],
      firstLoad: true,
      isBR: false,
      nacionalidades: [],
      ufsNascimento: [],
      citiesNascimento: []
    }
  },
  computed: {
    validateConjuge() {
      if (this.estadosCivis.length) {
        const currentMaritalState = this.estadosCivis.find(estado => estado.id === this.dadosPessoais.estadoCivil)
        if (currentMaritalState) return currentMaritalState.descricao.toLowerCase() === 'casado' ? 'required|fullName' : ''
      }
      return ''
    },
    computedNacionalidade() {
      return this.dadosPessoais.nacionalidade
    },
    computedUF() {
      return this.dadosPessoais.ufNascimento
    },
    computedNacionalidades() {
      return this.nacionalidades.map(n => ({ descricao: n }))
    },
    computedUfs() {
      return this.ufsNascimento.map(uf => ({ descricao: uf }))
    },
    computedCities() {
      return this.citiesNascimento.map(city => ({ descricao: city }))
    }
  },
  methods: {
    async getCep() {
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.dadosPessoais.endereco_CEP)) {
        try {
          const { cidade, uf, complemento, logradouro, bairro } = (await Cep.getCep(this.dadosPessoais.endereco_CEP)).data.data
          await this.getCities(uf)
          this.dadosPessoais = { ...this.dadosPessoais, endereco_Cidade: cidade, endereco_UF: uf, endereco_Complemento: complemento, endereco_Logradouro: logradouro, endereco_Bairro: bairro }
        } catch (error) {
          console.error(error)
        }
      }
    },
    async getUfs() {
      try {
        this.ufsNascimento = (await Cep.getUfs()).data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getCitiesNascimento(uf, cidade) {
      try {
        this.citiesNascimento = (await Cep.getCities(uf)).data.data
        this.cidadeNascimento = cidade
      } catch (err) {
        console.error(err)
      }
    },
    async getCities(uf) {
      try {
        const cities = (await Cep.getCities(uf || this.dadosPessoais.endereco_UF)).data.data
        this.cities = cities.map(city => ({ name: city }))
      } catch (err) {
        console.error(err)
      }
    },
    checkPercentage() {
      if (this.dadosPessoais.porcentagemParticipacao > 10000) this.dadosPessoais.porcentagemParticipacao = 10000
    },
    async submit() {
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            await Forms.putRequesterData({
              ...this.dadosPessoais,
              nomeCompleto: this.capitalizeName(this.dadosPessoais.nomeCompleto),
              nomeConjugeCompanheiro: this.capitalizeName(this.dadosPessoais.nomeConjugeCompanheiro),
              nomeMae: this.capitalizeName(this.dadosPessoais.nomeMae),
              nomePai: this.dadosPessoais.nomePai !== '' ? this.capitalizeName(this.dadosPessoais.nomePai) : undefined,
              referenciaPessoal1_Nome: this.capitalizeName(this.dadosPessoais.referenciaPessoal1_Nome),
              porcentagemParticipacao: (+this.dadosPessoais.porcentagemParticipacao) / 100,
              telefoneResidencial: this.dadosPessoais.telefoneResidencial && this.dadosPessoais.telefoneResidencial.trim().length ? this.dadosPessoais.telefoneResidencial : null
            })
            await Forms.putContactAuthorization(this.autorizacoesContato)
            this.$store.dispatch('notification', { type: 'success', message: 'Dados salvos com sucesso!' })
            this.$emit('updateBusiness')
            this.$router.push('/tomador/perfil/quadro-socios')
          } catch (error) {
            this.loading = false
            //this.$store.dispatch('notification', { type: 'error', message: 'Houve um erro. Revise o formulário.' })
            if (error.response.data.erros) {
              Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))

              let erros = "";
              for (var e in error.response.data.erros) {
                erros += error.response.data.erros[e] + ' ';
              }

              this.$store.dispatch('notification', { type: 'error', message: erros })
            }


          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    }
  },
  watch: {
    computedNacionalidade() {
      this.isBR = this.dadosPessoais.nacionalidade === 'Brasileiro'
      if (this.isBR) {
        if (this.firstLoad) {
          this.getUfs()
          if (this.dadosPessoais.ufNascimento !== '') this.getCitiesNascimento(this.dadosPessoais.ufNascimento, this.dadosPessoais.cidadeNascimento)
          this.firstLoad = false
        } else {
          this.dadosPessoais.ufNascimento = -1
          this.dadosPessoais.cidadeNascimento = -1
        }
      } else {
        if (!this.firstLoad) {
          this.dadosPessoais.ufNascimento = ''
          this.dadosPessoais.cidadeNascimento = ''
        }
      }
    },
    computedUF() {
      if (this.isBR && this.dadosPessoais.ufNascimento !== '' && this.dadosPessoais.ufNascimento !== -1) {
        this.getCitiesNascimento(this.dadosPessoais.ufNascimento, this.dadosPessoais.cidadeNascimento)
      }
    }
  },
  async mounted() {
    try {
      const enums = (await Forms.getEnums()).data.data
      this.sexos = enums.sexo
      this.tiposResidencia = enums.tipoResidencia
      this.temposResidencia = enums.tempoResidencia
      this.estadosCivis = enums.estadoCivil
      this.nacionalidades = enums.nacionalidades
    } catch (err) {
      console.error(err)
    }

    try {
      const ufs = (await Cep.getUfs()).data.data
      this.ufs = ufs.map(uf => ({ name: uf }))
    } catch (err) {
      console.error(err)
    }

    try {
      const response = (await Forms.getRequesterData()).data.data
      if (response) {
        if (response.endereco_UF) {
          await this.getCities(response.endereco_UF)
        }
        response.porcentagemParticipacao = response.porcentagemParticipacao * 100;
        Object.keys(response).forEach(key => {
          if (response[key] !== null && response[key] !== '') this.dadosPessoais[key] = response[key]
        })
      }
    } catch (err) {
      console.error(err)
    }

    try {
      const data = (await Forms.getContactAuthorization()).data.data

      if (data) {
        this.autorizacoesContato = data
      } else {
        this.autorizacoesContato = {
          email: true,
          sms: true,
          telefone: true,
          whatsApp: true
        }
      }
    } catch (err) {
      console.error(err)
    }

    this.loadingPage = false
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-primary text-center mt-4 mb-8
  @screen lg
    @apply text-2xl

.form_title
  @apply font-bold text-black mt-6 mb-1
  @screen lg
    @apply text-xl

.row
  @apply block flex flex-col
  @screen lg
    @apply flex flex-row flex-wrap

.subtitle
  @apply text-sm opacity-75

.label
  @apply text-sm opacity-75 cursor-pointer mr-2

.radio
  @apply mr-1 my-auto

.text
  @apply text-sm opacity-75 mb-2

.backdrop
  @apply absolute w-full h-full bg-white text-center text-4xl text-primary
  z-index: 60
  opacity: 0.9

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)

</style>
